import { Fragment, useContext } from 'react';
import Image from '@next/image';
import classNames from 'classnames';

import config from 'config';
import palette from 'theme/palette';
import { FilesContext } from '@providers/FilesProvider';
import Link from '@components/atoms/Link';
import Typography from '@components/atoms/Typography';
import Particles from '@components/Particles';
import NoSSR from '@misc/NoSSR';
import { FormattedMessage } from 'utils/intl';

import { BusinessStandardTile, TileType } from './BusinessStandards.types';
import { tiles, typesMap } from './BusinessStandards.consts';
import classes from './BusinessStandards.module.scss';
import messages from './BusinessStandard.messages';

const { routes } = config;

const BusinessStandards = (): JSX.Element => {
  const { getPageImageConfigs } = useContext(FilesContext);

  const classMap: Partial<Record<TileType, string>> = {
    STANDARD: classes.standard,
    STANDARD_PLUS: classes.standardPlus,
    DEFAULT: classes.standardPlus,
  };

  const containerClassMap: Record<BusinessStandardTile['size'], string> = {
    large: classes.large,
    vertical: classes.vertical,
    small: classes.small,
  };

  const buttonClassMap: Partial<Record<TileType, string>> = {
    STANDARD: classes.standardButton,
    STANDARD_PLUS: classes.standardPlusButton,
    DEFAULT: classes.standardPlusButton,
  };

  return (
    <div className={classes.container}>
      {tiles.map(
        ({
          id,
          title,
          type,
          size,
          description,
          smallDescription,
          image,
          backgroundImage,
          showOnMobile,
          searchAlias,
          items,
        }) => {
          const [imageConfig] = getPageImageConfigs([image]);

          return (
            <Link
              key={id}
              className={classNames(classes.tile, classMap[type], containerClassMap[size], {
                [classes.hideOnMobile]: !showOnMobile,
              })}
              href={`${routes.search.href}/${searchAlias}`}
            >
              {size === 'large' && (
                <NoSSR>
                  <div className={classes.dots} data-eid="particles">
                    <Particles
                      count={10}
                      options={{
                        color: palette.blue[100],
                        blur: { min: 0, max: 5 },
                        twinkle: { min: 0.2, max: 0.3 },
                        offset: { x: 20, y: 20 },
                      }}
                    />
                  </div>
                </NoSSR>
              )}
              <div className={classes.image}>
                <div className={classNames(classes.imageInner, { [classes.largeImage]: size === 'large' })}>
                  {imageConfig && (
                    <Image
                      layout="fill"
                      objectFit="contain"
                      alt={image}
                      resolveConfig={imageConfig}
                      hasLazyLoad={false}
                      hasPreload
                    />
                  )}
                </div>
              </div>
              <div className={classes.content}>
                <Typography variant="h2" weight="700" renderAs="div" className={classes.title}>
                  {title}
                </Typography>
                {type !== 'DEFAULT' && (
                  <Typography variant="body1" renderAs="div" className={classes.type}>
                    {typesMap[type]}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  renderAs="div"
                  className={classNames(classes.description, { [classes.showDescription]: !smallDescription })}
                >
                  {description}
                </Typography>
                {smallDescription && (
                  <Typography variant="body2" renderAs="div" className={classes.smallDescription}>
                    {smallDescription}
                  </Typography>
                )}
                {items?.length && (
                  <div className={classes.itemsWrapper}>
                    {size === 'vertical' && (
                      <div className={classNames(classes.image, classes.smallImage)}>
                        <div className={classes.imageInner}>
                          {imageConfig && (
                            <Image
                              layout="fill"
                              objectFit="contain"
                              alt={image}
                              resolveConfig={imageConfig}
                              hasLazyLoad={false}
                              hasPreload
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className={classes.items}>
                      {items?.map(({ id: itemId, name, values, icon }) => {
                        const [iconConfig] = getPageImageConfigs([icon]);
                        return (
                          <div key={itemId} className={classes.item}>
                            <div className={classes.icon}>
                              {iconConfig && (
                                <Image layout="fill" alt={icon} resolveConfig={iconConfig} hasLazyLoad={false} />
                              )}
                            </div>
                            <Typography variant="body2" renderAs="div" className={classes.itemText}>
                              {name}{' '}
                              {values &&
                                values.map((value, i) => (
                                  <Fragment key={value}>
                                    {!!i && <FormattedMessage {...messages.or} />}
                                    <b>{value}</b>
                                  </Fragment>
                                ))}
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <Typography
                  variant="body2"
                  renderAs="div"
                  className={classNames(classes.button, buttonClassMap[type || 'STANDARD_PLUS'])}
                >
                  <FormattedMessage {...messages.seeProducts} />
                </Typography>
              </div>
              {backgroundImage && (
                <div
                  className={classNames(size === 'large' ? classes.largeBackground : classes.backgroundImage, {
                    [classes.rotateImage]: type === 'DEFAULT',
                    [classes.pulseImage]: type === 'STANDARD_PLUS',
                  })}
                >
                  {backgroundImage}
                </div>
              )}
            </Link>
          );
        }
      )}
    </div>
  );
};

export default BusinessStandards;

import Message from 'types/Message';
import BiznesoweBlueIcon from 'assets/icons/biznesowe-blue.svg';
import PlusLightIcon from 'assets/icons/plus-light.svg';
import SettingsLightIcon from 'assets/icons/settings-light.svg';
import { FormattedMessage } from 'utils/intl';
import getListWithId from 'utils/getListWithId';

import { BusinessStandardTile, TileType } from './BusinessStandards.types';
import messages from './BusinessStandard.messages';

export const tiles: BusinessStandardTile[] = getListWithId([
  {
    title: <FormattedMessage {...messages.laptops} />,
    description: <FormattedMessage {...messages.laptopsDescription} />,
    smallDescription: <FormattedMessage {...messages.laptopsSmallDescription} />,
    type: 'STANDARD',
    size: 'large',
    image: 'laptop-standard',
    backgroundImage: <BiznesoweBlueIcon width={282} />,
    showOnMobile: true,
    items: getListWithId([
      {
        name: <FormattedMessage {...messages.processor} />,
        values: ['i3', 'i5'],
        icon: 'processor-icon',
      },
      {
        name: <FormattedMessage {...messages.ramMemory} />,
        values: ['8GB'],
        icon: 'ram-memory-icon',
      },
      {
        name: <FormattedMessage {...messages.ssdDisk} />,
        values: ['256GB'],
        icon: 'disk-icon',
      },
      {
        name: <FormattedMessage {...messages.screen} />,
        values: ['15″ FullHD'],
        icon: 'resolution-icon',
      },
      {
        name: <FormattedMessage {...messages.integratedGraphics} />,
        icon: 'integrated-graphics-icon',
      },
    ]),
    searchAlias: 'laptopy-biznesowy-standard',
  },
  {
    title: <FormattedMessage {...messages.computers} />,
    description: <FormattedMessage {...messages.computersDescription} />,
    smallDescription: <FormattedMessage {...messages.laptopsSmallDescription} />,
    type: 'STANDARD',
    size: 'vertical',
    image: 'computer-standard',
    showOnMobile: true,
    items: getListWithId([
      {
        name: <FormattedMessage {...messages.processor} />,
        values: ['i3', 'i5'],
        icon: 'processor-icon',
      },
      {
        name: <FormattedMessage {...messages.ramMemory} />,
        values: ['8GB'],
        icon: 'ram-memory-icon',
      },
      {
        name: <FormattedMessage {...messages.ssdDisk} />,
        values: ['256GB'],
        icon: 'disk-icon',
      },
      {
        name: <FormattedMessage {...messages.integratedGraphics} />,
        icon: 'integrated-graphics-icon',
      },
    ]),
    searchAlias: 'komputery-biznesowy-standard',
  },
  {
    title: <FormattedMessage {...messages.monitors} />,
    description: <FormattedMessage {...messages.monitorsDescription} />,
    type: 'STANDARD',
    size: 'vertical',
    image: 'monitor-standard',
    items: getListWithId([
      {
        name: <FormattedMessage {...messages.screenDiagonal} />,
        values: ['24 cale'],
        icon: 'diagonal-icon',
      },
      {
        name: <FormattedMessage {...messages.screenResolution} />,
        values: ['FullHD'],
        icon: 'resolution-icon',
      },
      {
        name: <FormattedMessage {...messages.rotatedScreen} />,
        values: ['Pivot'],
        icon: 'resolution-icon',
      },
      {
        name: <FormattedMessage {...messages.heightRegulation} />,
        icon: 'height-icon',
      },
      {
        name: <FormattedMessage {...messages.wideScreen} />,
        icon: 'height-icon',
      },
    ]),
    searchAlias: 'monitory-biznesowy-standard',
  },
  {
    title: <FormattedMessage {...messages.laptops} />,
    description: <FormattedMessage {...messages.laptopsPlusDescription} />,
    type: 'STANDARD_PLUS',
    size: 'small',
    image: 'laptop-standard-plus',
    backgroundImage: <PlusLightIcon width={111} />,
    searchAlias: 'laptopy-biznesowy-standard-plus',
  },
  {
    title: <FormattedMessage {...messages.computers} />,
    description: <FormattedMessage {...messages.computersPlusDescription} />,
    type: 'STANDARD_PLUS',
    size: 'small',
    image: 'computer-standard-plus',
    backgroundImage: <PlusLightIcon width={111} />,
    searchAlias: 'komputery-biznesowy-standard-plus',
  },
  {
    title: <FormattedMessage {...messages.workstations} />,
    description: <FormattedMessage {...messages.workstationsDescription} />,
    type: 'DEFAULT',
    size: 'small',
    image: 'workstation',
    backgroundImage: <SettingsLightIcon width={112} />,
    searchAlias: 'stacje-robocze',
    showOnMobile: true,
  },
  {
    title: <FormattedMessage {...messages.mobileWorkstations} />,
    description: <FormattedMessage {...messages.mobileWorkstationsDescription} />,
    type: 'DEFAULT',
    size: 'small',
    image: 'mobile-workstation',
    backgroundImage: <SettingsLightIcon width={112} />,
    searchAlias: 'mobilne-stacje-robocze',
    showOnMobile: true,
  },
]);

export const typesMap: Partial<Record<TileType, Message>> = {
  STANDARD: <FormattedMessage {...messages.businessStandard} />,
  STANDARD_PLUS: <FormattedMessage {...messages.businessStandardPlus} />,
  DEFAULT: '',
};
